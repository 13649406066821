<template>
  <div>
    <div class="login-page registration-page">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <!-- Sidebar -->
          <div class="col-md-4">
            <div class="generic-sidebar text-center" style="max-height: 762px">
              <img
                style="height: 100px; margin-bottom: 25px"
                src="/img/ship-icon.svg"
              />
              <h4>Unauthorized</h4>
              <p class="mt-25">
                If you believe you encountered this page in error, please let us
                know by emailing
                <a href="mailto:info@ashoreapp.com">info@ashoreapp.com</a>
              </p>
              <div class="sidebar-button-group">
                <a href="/" class="button button-dark button-gray button-block"
                  >Log In</a
                >
                <a
                  href="/forgot-password"
                  class="button button-dark button-gray button-block"
                  >Forgot My Password</a
                >
                <a
                  href="/sign-up"
                  class="button button-dark button-gray button-block"
                  >Create an Account</a
                >
              </div>
            </div>
          </div>
          <!-- /Sidebar -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.login-page {
  background-color: #019255;
}
</style>
